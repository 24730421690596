import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { MemoSinglePageQuery } from "../../../types/gatsby-graphql.generated"
import { compareByDate } from "../../base/date"
import { jsonBreadcrumbListForSinglePage } from "../../json/breadcrumb"
import { jsonMemo } from "../../json/content/memo"
import { SinglePageHeadLayout, SinglePageLayout } from "../../layouts/single"
import {
    ContentHeader,
    InfoGroup,
    InfoWith,
    KindLabel,
    LabelGroup,
    OthersInTopicSection,
} from "../../views/content"
import { Markdown } from "../../views/markdown"

export function Head({ data }: PageProps<MemoSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={false}
            path={path}
            title={title}
            description={description}
            json={[
                jsonBreadcrumbListForSinglePage({
                    kind: Kind.Memo,
                    title,
                    path,
                }),
                jsonMemo(markdownRemark),
            ]}
        />
    )
}

export default function MemoSinglePage({
    data,
}: PageProps<MemoSinglePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, topics, html, contentId } = markdownRemark

    const predicate = (item: { contentId: string }) =>
        item.contentId !== contentId

    return (
        <SinglePageLayout isHome={false}>
            <ContentHeader>
                <LabelGroup>
                    <KindLabel kind={Kind.Memo} />
                </LabelGroup>
                <h1>{title}</h1>
                <InfoGroup>
                    <InfoWith {...markdownRemark} />
                </InfoGroup>
            </ContentHeader>
            <Markdown html={html} />
            <OthersInTopicSection
                kind={Kind.Memo}
                topics={topics}
                extract={topic => {
                    return topic.memosInTopic
                        ?.filter(predicate)
                        .sort(compareByDate)
                }}
            />
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query MemoSinglePage($id: String!) {
        markdownRemark(
            frontmatter: { id: { eq: $id } }
            published: { eq: true }
        ) {
            contentId
            path
            title
            description
            updatedAt
            publishedAt
            html
            topics {
                path
                title
                memosInTopic {
                    contentId
                    path
                    title
                    updatedAt
                    updatedAtTimestamp
                    publishedAt
                    publishedAtTimestamp
                    topics {
                        path
                        title
                    }
                }
            }
        }
    }
`
